import React, { useContext, useCallback, useState, useEffect } from "react";
import { SurveyContext } from "../SurveyProvider";
import "./Question.scss";

const Question = ({
  question,
  scores,
  idx,
  category,
  subcategory,
  uuid,
  answerAlternatives,
}) => {
  const { score, setScore } = useContext(SurveyContext);
  const [hasScores, setHasScores] = useState(false);

  useEffect(() => {
    var newScore = Object.assign({}, score);

    newScore[category] = newScore[category] ? newScore[category] : {};
    newScore[category][subcategory] = newScore[category][subcategory]
      ? newScore[category][subcategory]
      : {};
    newScore[category][subcategory][idx] = { score: null, id: uuid, pos: 3 };
    setScore(newScore);
  }, []);

  const updateQuestionScore = useCallback(
    (v, key) => {
      var newScore = Object.assign({}, score);

      newScore[category] = newScore[category] ? newScore[category] : {};
      newScore[category][subcategory] = newScore[category][subcategory]
        ? newScore[category][subcategory]
        : {};
      newScore[category][subcategory][idx] = { score: v, id: uuid, pos: key };
      setScore(newScore);
    },
    [idx, uuid, category, subcategory, setScore]
  );

  return (
    <div className="question-container">
      <p className="question-box">{question}</p>
      <span>
        {scores.map((s, key) => {
          return (
            <label className="radio-container" key={key}>
              <div>
                <p className="radio-btn-tip">{answerAlternatives[key]}</p>
                <input
                  type="radio"
                  name={"radio" + uuid}
                  value={s}
                  id={s}
                  onChange={() => updateQuestionScore(s, key)}
                />
                <span className="checkmark"></span>
              </div>
            </label>
          );
        })}
      </span>
    </div>
  );
};

export default Question;
