import React, { createContext, useState, useMemo } from "react";
import t from "../translations";

const LangContext = createContext();
const getDefault = () => {
  return "no";
};

const LangContextProvider = ({ children }) => {
  const [lang, setLang] = useState(getDefault);
  const translations = useMemo(() => t[lang], [lang]);

  return (
    <LangContext.Provider value={{ lang, setLang, translations }}>
      {children}
    </LangContext.Provider>
  );
};

const LangContextConsumer = LangContext.Consumer;
export { LangContext, LangContextProvider, LangContextConsumer };
