export default {
  locale: "no",

  infoPage: {
    0: "Å ha kunnskap om befolkningens behov, samskape fremtidige løsninger og legge til rette for reell medvirkning der alle aktører blir hørt og involveres tidlig nok i prosessen.",
    1: "Nærmiljøkvaliteter har stor innvirkning på folkehelse, trivsel og livskvalitet. Nærmiljøet er en samlebetegnelse for de ulike fysiske og sosiale forholdene i et lokalsamfunn og omfatter blant annet tjenester, nærmiljøfunksjoner, møteplasser og muligheten for sosiale nettverk og fellesskap.",
    2: "Tilgang til goder kan være ulikt fordelt i befolkningen. Gjennom planleggingen kan vi blant annet tilstrebe å utjevne forskjeller blant annet ved å legge til rette for ikke- kommersielle, inkluderende møteplasser, gode boliger og bomiljø for alle og at alle har like påvirkningsmuligheter.",
  },

  welcomeScreen: {
    title: "Velkommen til det sosiale barometeret!",
    subTitle:
      "-	Et verktøy hvor du kan teste hvor godt ditt by- og stedsutviklingsprosjekt svarer på sosial bærekraft – utviklet av Asplan Viak.",
    text:
      "Gjennom en rekke spørsmål inviteres du til å reflektere over sosial bærekraft i prosjektet og du vil avslutningsvis få et resultat på hvor det scorer bra og hvor du bør vurdere å legge inn en ekstra innsats. Avslutningsvis vises det til en rekke verktøy som kan brukes. Ta dem i bruk selv, eller gå inn <a rel=noreferrer target=_blank href=https://www.asplanviak.no/tjenester/sosial-baerekraft/>her</a> for å kontakte Asplan Viak. Ta på deg de sosiale brillene og ta <b >temperaturen</b> på ditt prosjekt!",
    btn: "Ta temperaturen",
  },

  welcomeInfoScreen: {
    subTitle:
      "Asplan Viak har valgt å fokusere på tre sentrale elementer av sosial bærekraft. Menneskelige behov i sentrum, inkluderende og robuste lokalsamfunn og sosial rettferdighet.",
    text:
      "Før du starter på spørsmålene ber vi deg om å tenke gjennom «Hva er det som er særlig utfordrende med hensyn til sosial bærekraft i ditt prosjekt?»",

    btn: "neste",
  },

  logInScreen: {
    title: "Hva vil du ta tempen på?",
    subTitle:
      "På slutten av spørreundersøkelsen vil du få oppgitt din score basert på din besvarelse. Vi kommer til å lagre besvarelsen din, samt bedrift- og prosjektnavn. Vi kommer ikke til å spørre om sensitive opplysninger om deg, din bedrift eller ditt prosjekt.",
    companyForm: "Oppgi firma/organisasjon/kommune",
    projectForm: "Oppgi prosjektnavn",
    btn: "start",
  },
  toolToggle: {
    results: "Resultat med verktøy",
    tools: "Kun verktøy",
  },
};
