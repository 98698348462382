import React from "react";
import "./Tools.scss";

const Tools = ({ toolsContent }) => {
  return (
    <div className="tools-container">
      {toolsContent.map((v, idx) => {
        return (
          <ToolBox key={idx + v.title} title={v.title} list={v.toolList} />
        );
      })}
    </div>
  );
};

export default Tools;

const ToolBox = ({ title, list }) => {
  return (
    <div className="tool-box-container">
      <h3>{title}</h3>
      <span>
        {list.map((tool, idx) => {
          return (
            <div key={idx + tool} className="tool-box">
              {tool}
            </div>
          );
        })}
      </span>
    </div>
  );
};
