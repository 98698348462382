import { useEffect, useState } from "react";

export default function useScore(score) {
  const [categoryScore, setCategoryScore] = useState(null);
  const [subcategoryScore, setSubcategoryScore] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!score) {
      return;
    }
    setLoading(true);
    var subTotalResults = [];

    const subResults = Object.keys(score).map((v) => {
      return Object.keys(score[v]).map((s) => {
        return Object.values(score[v][s])
          .map((s) => {
            return s.score;
          })
          .reduce((a, b) => {
            if (a === null && b === null) {
              return null;
            } else {
              return a + b;
            }
          });
      });
    });

    for (var i = 0; i < subResults.length; i++) {
      subTotalResults[i] = subResults[i].reduce((a, b) => {
        if (a === null && b === null) {
          return null;
        } else {
          return a + b;
        }
      });
    }

    setSubcategoryScore(subResults);
    setCategoryScore(subTotalResults);

    setLoading(false);
  }, [score]);

  return { categoryScore, subcategoryScore, loading };
}
