import React from "react";
import { Link } from "react-router-dom";
import Model from "../static/model.png";
import { useTranslate } from "react-translate";
import "./WelcomeInfoScreen.scss";

const WelcomeInfoScreen = ({ to }) => {
  const t = useTranslate("welcomeInfoScreen");
  return (
    <div className="welcome-info-screen-container">
      <div>
        <img src={Model} alt="sosial-bærekraft-modell" />
        <b>{t("subTitle")}</b>
        <p>{t("text")}</p>

        <Link className="btn" to={to}>
          {t("btn")}
        </Link>
      </div>
    </div>
  );
};

export default WelcomeInfoScreen;
