import React, { useContext, useEffect } from "react";
import { SurveyContext } from "../SurveyProvider";
import { getThermostatImage } from "../components/Thermostat";
import request from "superagent";
import nullTemp from "../static/null_temp.png";
import arrow from "../static/arrow.png";
import "./TotalResults.scss";

const TotalResults = ({ setShowSummaryView }) => {
  const {
    results,
    score,
    config,
    companyName,
    projectName,
    apiUrl,
  } = useContext(SurveyContext);

  useEffect(() => {
    if (!score || !companyName || !projectName) return;

    const object = {
      name: projectName,
      company: companyName,
      answers: [],
    };

    Object.keys(score).map((i) => {
      Object.keys(score[i]).map((j) => {
        Object.keys(score[i][j]).map((k) => {
          object.answers.push({
            questionUuid: score[i][j][k].id,
            scoreIdx: score[i][j][k].pos,
          });
        });
      });
    });

    const req = request.post(`${apiUrl}Survey`).send(object);

    req.catch((err) => {
      if (err.code && err.code === "ABORTED") return;
      console.warn("Request failed", err);
    });
  }, [score, companyName, projectName, apiUrl]);

  return (
    <div className="total-results-container">
      <h2>Din totale score</h2>
      <span className="results-thermostat-container">
        {results.categoryScore.map((s, idx) => {
          const totalAmountOfQuestions = Object.keys(score[idx])
            .map(
              (i) =>
                score[idx][i].filter((j) => {
                  if (j.score) {
                    return j.score;
                  }
                  return 0;
                }).length
            )
            .reduce((a, b) => {
              if (a === 0 && b === 0) {
                return 0;
              } else {
                return a + b;
              }
            });

          const scorePercent =
            s && totalAmountOfQuestions !== 0
              ? (s / totalAmountOfQuestions).toFixed(0) // Max score is 100.
              : "-";

          const scoreImg =
            scorePercent !== "-"
              ? getThermostatImage(config[idx].color, scorePercent)
              : nullTemp;

          return (
            <div key={idx + s + config[idx].title}>
              <img src={scoreImg} alt="total-score-termostat" />
              <h3>{config[idx].title}</h3>
              <h4>
                {scorePercent !== "-" ? scorePercent + " %" : scorePercent}
              </h4>
            </div>
          );
        })}
      </span>
      <footer>
        <button
          onClick={() => {
            setShowSummaryView(true);
          }}
        >
          Videre resultater og verktøy
          <img src={arrow} alt="neste-knapp" />
        </button>
      </footer>
    </div>
  );
};

export default TotalResults;
