import React from "react";
import nullTemp from "../static/null_temp.png";
import "./Thermostat.scss";

const Thermostat = ({ title, color, score, amountOfQuestions }) => {
  const scorePercent =
    score && amountOfQuestions ? (score / amountOfQuestions).toFixed(0) : "-";

  const scoreImg = score ? getThermostatImage(color, scorePercent) : nullTemp;

  return (
    <div className="thermostat-container">
      <h2>{title}</h2>
      <span>
        <img className="thermostat-img" src={scoreImg} alt="termometer-bilde" />
        <h3>{scorePercent !== "-" ? scorePercent + " %" : scorePercent}</h3>
      </span>
    </div>
  );
};

export default Thermostat;

export function getThermostatImage(color, scorePercent) {
  if (scorePercent === "-") {
    return;
  }
  const baseUrl = "../static/result_images/";

  if (scorePercent >= 100) {
    return baseUrl + color + "/" + color + "_temp_full.png";
  } else if (scorePercent >= 80) {
    return baseUrl + color + "/" + color + "_temp_hoy.png";
  } else if (scorePercent >= 60) {
    return baseUrl + color + "/" + color + "_temp_medium_hoy.png";
  } else if (scorePercent >= 40) {
    return baseUrl + color + "/" + color + "_temp_medium.png";
  } else if (scorePercent >= 20) {
    return baseUrl + color + "/" + color + "_temp_medium_lav.png";
  } else {
    return baseUrl + color + "/" + color + "_temp_lav.png";
  }
}
