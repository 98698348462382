import React, { useState, useContext } from "react";
import ToolToggle from "./ToolToggle";
import Results from "./Results";
import { SurveyContext } from "../SurveyProvider";
import Tools from "./Tools";
import arrow from "../static/arrow.png";
import "./SummaryView.scss";

const SummaryView = ({ setShowSummaryView }) => {
  const [showResult, setShowResult] = useState(true);
  const { config, results, score } = useContext(SurveyContext);

  return (
    <div className="summary-view-container">
      <button
        onClick={() => {
          setShowSummaryView(false);
        }}
      >
        <img src={arrow} alt="neste-knapp" />
        Se total score
      </button>
      <ToolToggle showResult={showResult} setShowResult={setShowResult} />
      {showResult ? (
        <Results
          results={results}
          config={config}
          score={score}
          subcategoryScore={results.subcategoryScore}
        />
      ) : (
        <Tools toolsContent={toolsContent} />
      )}
      <div>
        <h5>
          Har du spørsmål kan du gå inn her:
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.asplanviak.no/tjenester/sosial-baerekraft/"
          >
            {" "}
            Sosial bærekraft - Asplan Viak AS
          </a>
        </h5>
      </div>
    </div>
  );
};

export default SummaryView;

const toolsContent = [
  {
    title: "Kontekst og stedsforståelse",
    toolList: [
      "Sosiokulturell stedsanalyse",
      "Aktøranalyse",
      "Folkehelsebarometer / levekårsstatistikk",
      "Digital spørreundersøkelse",
      "Stedsobservasjon",
      "Intervju",
      "Folkehelsebarometer",
      "SSB",
      "Ungdata undersøkelse",
    ],
  },
  {
    title: "Medvirkning / samskaping / prosessledelse",
    toolList: [
      "Åpent møte",
      "Workshop",
      "Intervju",
      "Fokusgruppeintervju",
      "1-1 møter",
      "Midlertidige tiltak",
      "Bylab",
      "Tilstedeværelse",
      "Ressursgrupper",
      "Design-thinking",
      "Folketråkk ved DOGA",
      "Diamanten",
    ],
  },
  {
    title: "Vurdere resultat / utredning / evalueringer",
    toolList: [
      "Sosial konsekvensanalyse",
      "Det sosiale barometer ",
      "BREEAM community",
    ],
  },
];
