import React from "react";
import { useTranslate } from "react-translate";
import "./ToolToggle.scss";

const ToolToggle = ({ showResult, setShowResult }) => {
  const t = useTranslate("toolToggle");
  return (
    <span className="tool-toggle-container">
      <h2
        className={showResult ? "active" : ""}
        onClick={() => setShowResult(true)}
      >
        {t("results")}
      </h2>
      <div />
      <h2
        className={!showResult ? "active" : ""}
        onClick={() => setShowResult(false)}
      >
        {t("tools")}
      </h2>
    </span>
  );
};

export default ToolToggle;
