import SurveyProvider from "./SurveyProvider";
import { useState } from "react";
import "./App.css";
import Survey from "./components/Survey";
import WelcomeScreen from "./views/WelcomeScreen";
import WelcomeInfoScreen from "./views/WelcomeInfoScreen";
import TotalResults from "./views/TotalResults";
import LogInScreen from "./views/LogInScreen";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { TranslatorProvider } from "react-translate";
import {
  LangContextConsumer,
  LangContextProvider,
} from "./context/LangContext";
import translations from "./translations/index.js";
import SummaryView from "./components/SummaryView";

const getTranslations = (lang) => {
  return translations[lang];
};

function App() {
  const [showToolPage, setShowToolPage] = useState(false);
  const [showSummaryView, setShowSummaryView] = useState(false);

  return (
    <SurveyProvider url={new URL(window.location)}>
      <LangContextProvider>
        <LangContextConsumer>
          {(props) => {
            return (
              <TranslatorProvider translations={getTranslations(props.lang)}>
                <Router>
                  <Route exact path="/">
                    <WelcomeScreen to={`/welcomesinfocreen/`} />
                  </Route>
                  <Route path={`/welcomesinfocreen/`}>
                    <WelcomeInfoScreen to={`/logInScreen`} />
                  </Route>
                  <Route path={`/logInScreen/`}>
                    <LogInScreen to={`/besvarelse/`} />
                  </Route>
                  <Route path={`/besvarelse/`}>
                    {!showToolPage ? (
                      <Survey
                        setShowToolPage={setShowToolPage}
                        showToolPage={showToolPage}
                      />
                    ) : showSummaryView ? (
                      <SummaryView setShowSummaryView={setShowSummaryView} />
                    ) : (
                      <TotalResults setShowSummaryView={setShowSummaryView} />
                    )}
                  </Route>
                </Router>
              </TranslatorProvider>
            );
          }}
        </LangContextConsumer>
      </LangContextProvider>
    </SurveyProvider>
  );
}

export default App;
