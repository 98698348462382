import React, { useContext, useEffect, useState } from "react";
import { SurveyContext } from "../SurveyProvider";
import "./ProgressionBar.scss";

const ProgressionBar = ({ page }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { config } = useContext(SurveyContext);
  var counter = 0;

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };
  useEffect(() => {
    setCurrentPage(currentPage + 1);
  }, [page, setCurrentPage]);

  return (
    <span className="progression-bar-container">
      <span className="background-line" />
      {config.map((v, idx) => {
        return (
          <ul key={generateKey(idx)}>
            {[...Array(v.subcategories.length)].map((q) => {
              counter = counter + 1;
              return (
                <li
                  key={generateKey(counter)}
                  className={
                    v.color + (counter === currentPage ? " active" : "")
                  }
                />
              );
            })}
          </ul>
        );
      })}
    </span>
  );
};
export default ProgressionBar;
