import React from "react";
import Thermostat from "./Thermostat";
import "./Results.scss";

const Results = ({ results, score, config, subcategoryScore }) => {
  return (
    <div className="results-container">
      {config.map((v, idx) => {
        const color = v.color;
        const title = v.title;
        return v.subcategories.map((s, i) => {
          const amountOfQuestions = score[idx][i].filter((j) => {
            if (j.score) {
              return j.score;
            }
            return 0;
          }).length;

          return (
            <div key={idx + s.title}>
              <h3 className={"main-category-title " + color}>
                Kategori: {title}
              </h3>
              <div className={color}>
                <Thermostat
                  title={s.title}
                  color={color}
                  score={subcategoryScore[idx][i]}
                  amountOfQuestions={amountOfQuestions}
                />
                <span>
                  <h4>Relevante verktøy:</h4>
                  {s?.tools.map((t, idx) => {
                    return (
                      <div
                        className={"inner-toolbox " + color}
                        key={t.uuid + idx}
                      >
                        {t.title}
                      </div>
                    );
                  })}
                </span>
              </div>
            </div>
          );
        });
      })}
    </div>
  );
};

export default Results;
