import { useParams } from "react-router-dom";

export default function useIntParam(param) {
  const params = useParams();

  if (!params[param]) return null;

  const value = parseInt(params[param]);
  if (isNaN(value)) return null;

  return value;
}
