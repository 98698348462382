import React from "react";
import "./QuestionGuide.scss";

const QuestionGuide = ({ questionTitle, color, answerAlternatives }) => {
  return (
    <div className="question-guide-container">
      <h2 className="question-title">{questionTitle}</h2>
      {answerAlternatives.map((a, idx) => {
        return (
          <div className={"question-alternatives " + color} key={idx}>
            {a}
          </div>
        );
      })}
    </div>
  );
};

export default QuestionGuide;
