import { useState } from "react";
//import { QuestionairContext } from "../SurveyProvider";
import Category from "./Category";
import Subcategory from "./Subcategory";
import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import ProgressionBar from "./ProgressionBar";
import "./Survey.scss";

export default function Questionair({ setShowToolPage, showToolPage }) {
  const [btnClicked, setBtnClicked] = useState(0);
  return (
    <div className="survey-container">
      <ProgressionBar page={btnClicked} />

      <Router>
        <Switch>
          <Route path={`/questionair/:category/:subcategory`}>
            <Subcategory
              setShowToolPage={setShowToolPage}
              setBtnClicked={setBtnClicked}
              btnClicked={btnClicked}
            />
          </Route>
          <Route path={`/questionair/:category`}>
            <Category />
          </Route>
          <Route>
            <Category />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}
