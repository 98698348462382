import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import useIntParam from "../hooks/useIntParam";
import { SurveyContext } from "../SurveyProvider";
import Question from "./Question";
import QuestionGuide from "./QuestionGuide";

const answerAlternatives = [
  "Liten grad",
  "Middels grad",
  "Høy grad",
  "Ikke relevant",
];

export default function Subcategory({
  setShowToolPage,
  setBtnClicked,
  btnClicked,
}) {
  const { config } = useContext(SurveyContext);
  const category = useIntParam("category");
  const subcategory = useIntParam("subcategory");
  const lastCategory = category === config.length - 1;
  const lastSubcategory =
    subcategory === config[category].subcategories.length - 1;
  const nextSubcategory = lastSubcategory ? 0 : subcategory + 1;
  const nextCategory = lastSubcategory ? category + 1 : category;
  const toUrl = lastSubcategory
    ? `/questionair/${nextCategory}`
    : `/questionair/${nextCategory}/${nextSubcategory}`;

  const questions = config[category].subcategories[subcategory].questions;
  const subcategoryTitle = config[category].subcategories[subcategory].title;
  const categoryColor = config[category].color;

  useEffect(() => {
    if (
      subcategory === config[category].subcategories.length - 1 &&
      category === config.length
    ) {
      setShowToolPage(true);
    }
    return;
  }, [category, lastSubcategory, config, setShowToolPage, subcategory]);

  return (
    <div className="subcategory-container">
      <div className="subcategory-questions-container">
        <QuestionGuide
          answerAlternatives={answerAlternatives}
          questionTitle={subcategoryTitle}
          color={categoryColor}
        />
        {questions.map((q, idx) => {
          return (
            <Question
              answerAlternatives={answerAlternatives}
              key={q.uuid}
              question={q.question}
              scores={q.scores}
              idx={idx}
              uuid={q.uuid}
              category={category}
              subcategory={subcategory}
            />
          );
        })}
      </div>
      {lastCategory && lastSubcategory ? (
        <button
          className="btn"
          onClick={() => {
            setShowToolPage(true);
          }}
        >
          Neste
        </button>
      ) : (
        <Link
          className="btn"
          to={toUrl}
          onClick={() => {
            setBtnClicked(btnClicked + 1);
          }}
        >
          Neste
        </Link>
      )}
    </div>
  );
}
