import React from "react";
import { Link } from "react-router-dom";
import glasses from "../static/glasses.png";
import asplanViakLogo from "../static/asplan_viak.svg";
import { useTranslate } from "react-translate";
import "./WelcomeScreen.scss";

const WelcomeScreen = ({ to }) => {
  const t = useTranslate("welcomeScreen");
  return (
    <>
      <div className="welcome-screen-container">
        <div>
          <header>
            <img src={asplanViakLogo} alt="asplan viak-logo" />
          </header>
          <h1 className="title">{t("title")}</h1>
          <img src={glasses} alt="glasses-logo" />
          <b>{t("subTitle")}</b>
          <p
            dangerouslySetInnerHTML={{
              __html: t("text"),
            }}
          />
          <Link className="btn" to={to}>
            {t("btn")}
          </Link>
        </div>
      </div>
      <footer>
        <img className="av-logo" src={asplanViakLogo} alt="asplan viak-logo" />
      </footer>
    </>
  );
};
export default WelcomeScreen;
