import React, { useEffect, useState } from "react";
import calculateScore from "./hooks/calculateScore";
import request from "superagent";

export const SurveyContext = React.createContext();

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:44381/";
export default function SurveyProvider({ children, url }) {
  const [config, setConfig] = useState();
  const [companyName, setCompanyName] = useState();
  const [projectName, setProjectName] = useState();
  const [score, setScore] = useState({});

  useEffect(() => {
    if (!config) {
      return;
    }
    config.map((cat, i) => {
      score[i] = score[i] || {};
      cat.subcategories.map((subcat, j) => {
        score[i][j] = score[i][j] || [];
        subcat.questions.map((q) => {
          score[i][j].push({ score: null, id: q.uuid, pos: 3 });
        });
      });
    });
  }, [config]);

  const results = calculateScore(score);

  //console.log("score: ", score);
  //console.log("res: ", results);
  //console. log("con:", config);

  useEffect(() => {
    request
      .get(`${apiUrl}Config`)
      .then((res) => res.body)
      .then((res) => {
        setConfig(res);
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <React.Fragment>
      {config && (
        <SurveyContext.Provider
          value={{
            config,
            score,
            setScore,
            results,
            setCompanyName,
            setProjectName,
            projectName,
            companyName,
            apiUrl,
          }}
        >
          {!config && <div>Laster</div>}
          {config && children}
        </SurveyContext.Provider>
      )}
      {!config && <div>Laster</div>}
    </React.Fragment>
  );
}
