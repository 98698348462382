import React, { useCallback, useContext, useState } from "react";
import { SurveyContext } from "../SurveyProvider";
import { useHistory } from "react-router-dom";
import { useTranslate } from "react-translate";
import "./LogInScreen.scss";

const LogInScreen = ({ to }) => {
  const t = useTranslate("logInScreen");
  const history = useHistory();
  const [projectName, setProjectFormName] = useState();
  const [companyName, setCompanyFormName] = useState();
  const { setProjectName, setCompanyName } = useContext(SurveyContext);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!companyName && !projectName) {
        return;
      }
      setProjectName(projectName);
      setCompanyName(companyName);
      history.push(to);
    },
    [projectName, companyName, setCompanyName, setProjectName, history, to]
  );

  return (
    <div className="login-screen-container">
      <div>
        <h2 className="title">{t("title")}</h2>
        <b>{t("subTitle")}</b>
        <form onSubmit={onSubmit}>
          <input
            id="query-field"
            type="text"
            className="login-input"
            placeholder={t("companyForm")}
            onChange={(e) => {
              setCompanyFormName(e.target.value);
            }}
          />

          <input
            id="query-field"
            type="text"
            className="login-input"
            placeholder={t("projectForm")}
            onChange={(e) => {
              setProjectFormName(e.target.value);
            }}
          />
          <button
            className={projectName && companyName ? "btn" : "btn inactive"}
            type="submit"
          >
            {t("btn")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LogInScreen;
