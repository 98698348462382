import { useContext } from "react";
import { Link } from "react-router-dom";
import useIntParam from "../hooks/useIntParam";
import { SurveyContext } from "../SurveyProvider";
import { useTranslate } from "react-translate";
import "./Category.scss";

export default function Category({ setBtnClicked, btnClicked }) {
  const { config } = useContext(SurveyContext);
  const category = useIntParam("category") || 0; // Hacky?
  const t = useTranslate("infoPage");
  const color = "../static/intro_" + config[category].color + ".png";

  return (
    <div className="introduction-page-content">
      <img alt="introduksjonsbilde" className="intro-page-img" src={color} />
      <div>
        <h2>{config[category].title}</h2>
        <p>{t(category)}</p>
        <Link className="btn" to={`/questionair/${category}/0`}>
          Neste
        </Link>
      </div>
    </div>
  );
}
